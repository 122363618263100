<template>
  <d-remote-monitoring-indicator-units
    class="monitoring-indicator-units"
  />
</template>

<script>

export default {
  name: 'RemoteMonitoringIndicatorUnits',
};
</script>

<style lang="scss">
  .monitoring-indicator-units {
    overflow: hidden;
    .b-table{
      display: inline-block;
      height: calc(100vh - 150px) !important;
      overflow: auto;
      thead {
        display: inline-table;
        position: sticky;
        width: 100%;
        top: 0;
        left: 0;
        width: 100%;
        background: #ffffff;
        tr {
          th {
            &:nth-child(1) {
              width: 36%;
            }
            &:nth-child(2) {
              width: 26%;
            }
            &:nth-child(3) {
              width: 14%;
            }
          }
        }
      }
      tbody {
        display: inline-table;
        width: 100%;
        tr {
          td {
            &:nth-child(1) {
              width: 36%;
            }
            &:nth-child(2) {
              width: 26%;
            }
            &:nth-child(3) {
              width: 14%;
            }
          }
        }
      }
    }
  }
</style>
